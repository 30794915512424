import React, { useEffect, useRef, useState } from "react";
import "./inputBox.css";

const InputBox = React.forwardRef(
  (
    {
      type,
      name,
      display,
      value,
      setValue,
      className = "",
      isRequired = false,
      isPassword = false,
      ...props
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false);
    const [trueType, setTrueType] = useState(type);

    return (
      <div className={"inputBox " + className}>
        <input
          type={trueType}
          name={name}
          value={value}
          required={isRequired}
          ref={ref}
          onChange={(e) => setValue(e.target.value)}
          {...props}
        />
        <span>{display}</span>

        {isPassword && !visible && (
          <svg
            onClick={() => {
              setVisible(true);
              setTrueType("text");
            }}
          >
            <use xlinkHref="#eye-icon" />
          </svg>
        )}
        {isPassword && visible && (
          <svg
            className="open"
            onClick={() => {
              setVisible(false);
              setTrueType("password");
            }}
          >
            <use xlinkHref="#eye-closed-icon" />
          </svg>
        )}
      </div>
    );
  }
);

const InputBox2 = React.forwardRef(
  (
    {
      type,
      name,
      display,
      value,
      setValue,
      className = "",
      isRequired = false,
      isPassword = false,
      ...props
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false);
    const [trueType, setTrueType] = useState(type);

    return (
      <div className={"inputBox2 " + className}>
        <p>{display} : </p>
        <input
          type={trueType}
          name={name}
          value={value}
          required={isRequired}
          ref={ref}
          onChange={(e) => setValue(e.target.value)}
          {...props}
        />

        {isPassword && !visible && (
          <svg
            onClick={() => {
              setVisible(true);
              setTrueType("text");
            }}
          >
            <use xlinkHref="#eye-icon" />
          </svg>
        )}
        {isPassword && visible && (
          <svg
            className="open"
            onClick={() => {
              setVisible(false);
              setTrueType("password");
            }}
          >
            <use xlinkHref="#eye-closed-icon" />
          </svg>
        )}
      </div>
    );
  }
);
const SearchBox = React.forwardRef(
  ({ type, name, value, setValue, className = "", isRequired = false, ...props }, ref) => {
    return (
      <div className={"searchBox " + className}>
        <input
          type={type}
          name={name}
          value={value}
          required={isRequired}
          ref={ref}
          onChange={(e) => setValue(e.target.value)}
          {...props}
        />
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0314 12.8995C15.0172 11.6672 15.6066 10.104 15.6066 8.40323C15.6066 4.42497 12.3816 1.19995 8.40335 1.19995C4.42509 1.19995 1.20007 4.42497 1.20007 8.40323C1.20007 12.3815 4.42509 15.6065 8.40335 15.6065C10.1042 15.6065 11.6673 15.0171 12.8996 14.0313L17.4338 18.5656C17.7463 18.8781 18.2531 18.8781 18.5657 18.5656C18.8782 18.253 18.8782 17.7462 18.5657 17.4336L14.0314 12.8995ZM8.40335 14.0138C5.30471 14.0138 2.79277 11.5019 2.79277 8.40323C2.79277 5.30459 5.30471 2.79265 8.40335 2.79265C11.502 2.79265 14.0139 5.30459 14.0139 8.40323C14.0139 11.5019 11.502 14.0138 8.40335 14.0138Z"
            fill="#0F0F0F"
          />
        </svg>
      </div>
    );
  }
);

const DropDownBox2 = React.forwardRef(
  (
    {
      type,
      name,
      display,
      value,
      setValue,
      className = "",
      isRequired = false,
      isPassword = false,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <div className={"dropDownBox2 " + className}>
        <p>{display} : </p>
        <select
          name={name}
          value={value}
          required={isRequired}
          ref={ref}
          onChange={(e) => setValue(e.target.value)}
          {...props}
        >
          {children}
        </select>
      </div>
    );
  }
);

const TextArea = React.forwardRef(
  ({ name, display, value, setValue, className = "", isRequired = false, ...props }, ref) => {
    return (
      <div className={"textArea " + className}>
        <p>{display} : </p>
        <textarea
          name={name}
          value={value}
          required={isRequired}
          ref={ref}
          onChange={(e) => setValue(e.target.value)}
          {...props}
        />
      </div>
    );
  }
);

const SmallInput = React.forwardRef(
  (
    {
      name,
      display,
      value,
      setValue,
      className = "",
      isRequired = false,
      onKeyPress,
      onKeyDown,
      isHighlighted,
      isAnimating,
      ...props
    },
    ref
  ) => {
    const handleInputChange = (newValue) => {
      // Ensure newValue is numeric characters only
      const numericValue = newValue.replace(/\D/g, ""); // Remove non-numeric characters
      setValue(numericValue); // Update state with the cleaned numeric value
    };

    return (
      <input
        type="text"
        name={name}
        value={value}
        required={isRequired}
        ref={ref}
        onChange={(e) => handleInputChange(e.target.value)}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        className={
          className +
          (isHighlighted ? " highlight" : "") +
          (isHighlighted && isAnimating ? " shake" : "")
        }
        {...props}
      />
    );
  }
);

const SmallGroup = React.forwardRef(
  (
    {
      name,
      display,
      value,
      setValue,
      className = "",
      isRequired = false,
      highlightKeys = [], // Use ticket numbers as keys
      isAnimating = false,
      ...props
    },
    ref
  ) => {
    // Generate a persistent unique ID for each input
    const generateId = () => `id-${Math.random().toString(36).substr(2, 9)}`;

    const [inputs, setInputs] = useState(value.map((val) => ({ id: generateId(), value: val }))); // Unique keys with values
    const inputRefs = useRef([]);

    const handleAddInput = () => {
      setInputs((prevInputs) => [...prevInputs, { id: generateId(), value: "" }]);
    };

    const handleInputChange = (index, newValue) => {
      const updatedInputs = [...inputs];
      updatedInputs[index].value = newValue;
      setInputs(updatedInputs);
    };

    const handleKeyPress = (index, event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (inputs[index].value !== "") {
          if (index === inputs.length - 1) {
            handleAddInput();
            setTimeout(() => inputRefs.current[index + 1].focus(), 0);
          } else {
            inputRefs.current[index + 1].focus();
          }
        }
      }
    };

    const handleKeyDown = (index, event) => {
      if (event.key === "Backspace" && inputs[index].value === "") {
        event.preventDefault();
        if (inputs.length > 1) {
          setInputs((prevInputs) => prevInputs.filter((_, i) => i !== index));
          setTimeout(() => {
            if (index > 0) inputRefs.current[index - 1].focus();
          }, 0);
        }
      }
    };

    const renderInputs = () => {
      return inputs.map((input, index) => (
        <SmallInput
          key={input.id} // Use persistent unique ID
          ref={(el) => (inputRefs.current[index] = el)}
          name={`${name}-${index}`}
          display={`${display} ${index + 1}`}
          value={input.value}
          setValue={(newValue) => handleInputChange(index, newValue)}
          onKeyPress={(event) => handleKeyPress(index, event)}
          onKeyDown={(event) => handleKeyDown(index, event)}
          className={className}
          isRequired={isRequired}
          isHighlighted={highlightKeys.includes(input.value)} // Highlight based on value
          isAnimating={isAnimating}
          {...props}
        />
      ));
    };

    // Update parent component with new values
    useEffect(() => {
      setValue(inputs.map((input) => input.value));
    }, [inputs]);

    return (
      <div className={"smallInputGroup " + className}>
        <p>{display} :</p>
        <div className="container" ref={ref} {...props}>
          {renderInputs()}
          <svg
            width="32"
            height="32"
            style={{
              padding: "8px",
              backgroundColor: "#1C274C",
              borderRadius: "20px",
              cursor: "pointer",
              marginLeft: "12px",
            }}
            onClick={handleAddInput}
          >
            <use xlinkHref="#plus-icon" />
          </svg>
        </div>
      </div>
    );
  }
);

const RangeInputBox = React.forwardRef(
  (
    {
      name,
      name2,
      display,
      value,
      value2,
      setValue,
      setValue2,
      className = "",
      isRequired = false,
      isRequired2 = false,
      placeholder,
      placeholder2,
      onKeyPress1,
      onKeyPress2,
      ...props
    },
    refs
  ) => {
    // Destructure the forwarded refs
    const [ref1, ref2] = refs;

    return (
      <div className={"RangeInputBox " + className}>
        <p>{display} : </p>
        <div className="container">
          <input
            type="text"
            name={name}
            value={value}
            required={isRequired}
            ref={ref1}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
            onKeyPress={onKeyPress1}
            {...props}
          />
          <svg width="40" viewBox="0 0 60 25" fill="none">
            <path
              d="M59.5085 10.921L49.0856 0.498605C48.449 -0.138954 47.33 -0.138954 46.6934 0.498605L45.7846 1.4069C45.1261 2.06641 45.1261 3.18104 45.7851 3.84055L51.6944 9.79078H8.28176L14.2203 3.81274C14.8798 3.15275 14.8798 2.0586 14.2203 1.3986L13.3125 0.481043C12.6744 -0.157005 11.5559 -0.161882 10.9193 0.475678L0.492022 10.8991C0.166169 11.2254 -0.00797697 11.66 0.000315699 12.1083C-0.00846477 12.5742 0.166169 13.0103 0.492022 13.3361L10.9144 23.7586C11.552 24.3966 12.67 24.3961 13.3071 23.7586L14.2149 22.8503C14.8754 22.1898 14.8754 21.1693 14.2149 20.5098L8.13493 14.481H51.89L45.7807 20.5381C45.4622 20.8556 45.2866 21.2556 45.2866 21.7078C45.2866 22.16 45.4617 22.5722 45.7807 22.8908L46.6885 23.7927C47.0075 24.1112 47.4324 24.2829 47.8851 24.2829C48.3383 24.2829 48.7627 24.1064 49.0817 23.7878L59.5085 13.36C59.8334 13.0347 60.008 12.5981 59.9997 12.1498C60.009 11.6835 59.8339 11.2469 59.5085 10.921Z"
              fill="#8B8B8B"
            />
          </svg>
          <input
            type="text"
            name={name2}
            value={value2}
            required={isRequired2}
            ref={ref2}
            onChange={(e) => setValue2(e.target.value)}
            placeholder={placeholder2}
            onKeyPress={onKeyPress2}
            {...props}
          />
        </div>
      </div>
    );
  }
);

export {
  InputBox,
  InputBox2,
  SearchBox,
  DropDownBox2,
  TextArea,
  SmallGroup,
  SmallInput,
  RangeInputBox,
};
