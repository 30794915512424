import React, { useEffect, useRef, useState } from "react";
import "./popup.css";
import useMyFetch from "../fetch/fetch";
import { useData } from "../dataProvider/dataProvider";
import { InputBox2, TextArea } from "../inputBox/inputBox";
import toast from "react-hot-toast";
import TicketVoucher from "../ticket/ticket";
import download from "downloadjs";
import { toPng } from "html-to-image";
import PopupBox from "./popup";
const PopupBox2 = ({ ticket_number, ticketsData, setTicketsData, close, actions, ...prop }) => {
  const { post } = useMyFetch();
  const [reservedList, setReservedList] = useState([]);
  const { ticketInfo, setLoadingScreen, userInfo, userList, setUserList } = useData();
  const [soldOut, setSoldOut] = useState(false);
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [byWho, setByWho] = useState(userInfo.id);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [canExport, setCanExport] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const nameInput = useRef(null);
  const phoneInput = useRef(null);
  const noteInput = useRef(null);
  const addressInput = useRef(null);
  const containerRef = useRef(null);

  const [showPopup, setShowPopup] = useState({
    resetTicket: false,
  });

  const fetchTickets = () => {
    post("ticket", { ticket_number: parseInt(ticket_number), lottery: ticketInfo.id }).then(
      (res) => {
        if (res.data.length > 0) {
          const firstTicket = res.data[0];
          console.log(firstTicket);
          if (parseInt(firstTicket.state) === 1) {
            setName(firstTicket.cus_name);
            setByWho(firstTicket.by_who);
            setNote(firstTicket.note);
            setPhoneNumber(firstTicket.cus_ph);
            setAddress(firstTicket.cus_address);
            setSoldOut(true);
          }
          setReservedList(res.data);
        }
        setLoadingScreen(false);
      }
    );
  };

  useEffect(() => {
    if (parseInt(userInfo.role) === 0) {
      post("employee/list").then((response) => {
        if (response.status !== "success") {
          toast.error(response.message);
          return;
        }
        setUserList(response.data);
      });
    }

    fetchTickets();

    // const intervalId = setInterval(() => {
    //   post("ticket", { ticket_number: parseInt(ticket_number), lottery: ticketInfo.id }).then(
    //     (res) => {
    //       setReservedList(res.data);
    //       if (res.data.length > 0) {
    //         const firstTicket = res.data[0];
    //         if (parseInt(firstTicket.state) === 1) {
    //           setSoldOut(true);
    //         }
    //       }
    //     }
    //   );
    // }, 1500);
    // return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let updatedData = ticketsData.filter((item) => item.ticket_number !== parseInt(ticket_number));

    reservedList.forEach((reserve) => {
      updatedData.push(reserve);
    });

    setTicketsData(updatedData);
  }, [reservedList]);

  useEffect(() => {
    // console.log("canExport triggered");
    // console.log(canExport, imageLoading);
    if (canExport && !imageLoading) {
      handleExportClick();
      // setLoadingScreen(false);
    }
  }, [canExport, imageLoading]);

  function KeyPressHandler(event, nextRef, isLast = false) {
    if (event.target.name === "phone") {
      if (!event.key.match(/^\d$/)) {
        event.preventDefault();
      }
    }
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.target.value !== "") {
        if (isLast) {
          return;
        }
        if (nextRef && nextRef.current) {
          nextRef.current.focus();
        }
      }
    }
  }

  const inputCheck = () => {
    if (name === "") {
      toast.error("Name Cannot be Empty");
      nameInput.current.focus();
      return false;
    }
    if (phoneNumber === "") {
      toast.error("Phone Number Cannot be Empty");
      phoneInput.current.focus();
      return false;
    }
    if (address === "") {
      toast.error("Address Cannot be Empty");
      addressInput.current.focus();
      return false;
    }
    return true;
  };

  const sold = () => {
    if (!inputCheck()) return;
    setLoadingScreen(true);
    setCanExport(false);

    post("ticket/sold", {
      name,
      ph_no: phoneNumber,
      address,
      note,
      ticket_number: parseInt(ticket_number),
      lottery: ticketInfo.id,
    }).then((res) => {
      if (res.status !== "success") {
        toast.error(res.message);
        setLoadingScreen(false);
        return;
      }
      setReservedList(res.data);
      setSoldOut(true);
      setCanExport(true);
    });
  };

  const reserve = () => {
    if (reservedList.length >= 5) {
      toast.error("Reserve List is Full");
      return;
    }
    if (!inputCheck()) return;
    setLoadingScreen(true);
    post("ticket/reserve", {
      name,
      ph_no: phoneNumber,
      address,
      note,
      ticket_number: parseInt(ticket_number),
      lottery: ticketInfo.id,
    }).then((res) => {
      if (res.status !== "success") {
        toast.error(res.message);
        setLoadingScreen(false);
        return;
      }
      setReservedList(res.data);
      setName("");
      setNote("");
      setPhoneNumber("");
      setAddress("");
      setLoadingScreen(false);
    });
  };

  const unreserve = (id) => {
    setLoadingScreen(true);

    post("ticket/unreserve", {
      id,
      ticket_number: parseInt(ticket_number),
      lottery: ticketInfo.id,
    }).then((res) => {
      if (res.status !== "success") {
        toast.error(res.message);
        setLoadingScreen(false);
        return;
      }
      setReservedList(res.data);
      setLoadingScreen(false);
    });
  };

  const ConfirmResetTicket = () => {
    setLoadingScreen(true);
    post("ticket/delete", {
      ticket_number: parseInt(ticket_number),
      lottery: ticketInfo.id,
    }).then((res) => {
      if (res.status !== "success") {
        toast.error(res.message);
        setLoadingScreen(false);
        return;
      }
      setSoldOut(false);
      post("ticket", { lottery: ticketInfo.id }).then((res) => {
        setTicketsData(res.data);
      });
      setName("");
      setPhoneNumber("");
      setAddress("");
      setNote("");
      setShowPopup({ ...showPopup, resetTicket: false });
      setLoadingScreen(false);
    });
  };

  const pin = (id) => {
    setLoadingScreen(true);
    post("ticket/pin", {
      id,
      ticket_number: parseInt(ticket_number),
      lottery: ticketInfo.id,
    }).then((res) => {
      if (res.status !== "success") {
        toast.error(res.message);
        setLoadingScreen(false);
        return;
      }
      setReservedList(res.data);
      setLoadingScreen(false);
    });
  };

  const handleExportClick = () => {
    if (containerRef.current) {
      setLoadingScreen(true);
      const currentDate = new Date();

      setTimeout(() => {
        toPng(containerRef.current)
          .then((dataUrl) => {
            // Ensure dataUrl is valid before proceeding
            if (!dataUrl) {
              console.error("Invalid dataUrl received.");
              setLoadingScreen(false);
              return;
            }

            download(
              dataUrl,
              currentDate.toLocaleString() + "-" + name + "-" + ticket_number + `.png`
            );
            setLoadingScreen(false);
          })
          .catch((error) => {
            console.error("Error exporting image:", error);
            setLoadingScreen(false);
          });
      }, 100);
    }
  };

  return (
    <div className="popup popup2">
      <div className="inner " {...prop}>
        <svg className="close" viewBox="0 0 24 24" fill="none" onClick={close}>
          <path
            d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
            fill="#555"
          />
        </svg>
        <div>
          <h3>{"Ticket Number - " + ticket_number}</h3>
          {soldOut && parseInt(userInfo.role) === 0 && (
            <p style={{ marginTop: "8px", textAlign: "center" }}>
              Sold by :&nbsp;
              {userList && userList.filter((item) => item.id === byWho)
                ? userList.filter((item) => item.id === byWho)[0]["name"]
                : "Unknown"}
            </p>
          )}
        </div>
        <InputBox2
          type="text"
          name="name"
          display="Name"
          value={name}
          setValue={setName}
          ref={nameInput}
          onKeyPress={(e) => KeyPressHandler(e, phoneInput)}
          placeholder="Enter Customer Name"
          autoComplete="off"
          disabled={soldOut}
          style={soldOut ? { border: "none" } : {}}
        />
        <InputBox2
          type="text"
          name="phone"
          display="Phone Number"
          value={phoneNumber}
          setValue={setPhoneNumber}
          ref={phoneInput}
          onKeyPress={(e) => KeyPressHandler(e, addressInput)}
          placeholder="Enter Username"
          autoComplete="off"
          disabled={soldOut}
          style={soldOut ? { border: "none" } : {}}
        />
        <TextArea
          name="address"
          display="Address"
          value={address}
          setValue={setAddress}
          ref={addressInput}
          onKeyPress={(e) => KeyPressHandler(e, noteInput)}
          placeholder="Enter Address"
          autoComplete="off"
          disabled={soldOut}
          style={soldOut ? { border: "none", color: "#000" } : {}}
        />
        <TextArea
          name="note"
          display="Note"
          value={note}
          setValue={setNote}
          ref={noteInput}
          onKeyPress={(e) => KeyPressHandler(e, null, true)}
          placeholder="Note..."
          autoComplete="off"
          disabled={soldOut}
          style={soldOut ? { border: "none", color: "#000" } : {}}
        />
        {!soldOut && (
          <div className="actions">
            <button className="gray" onClick={reserve}>
              Reserve
            </button>
            <button className="success" onClick={sold}>
              Sell
            </button>
          </div>
        )}
        {!soldOut && reservedList.length > 0 && (
          <div className="reserved-list">
            {/* <p>hello</p> */}
            {reservedList
              .sort((a, b) => b.pin - a.pin)
              .map((list, index) =>
                parseInt(list.state) === 2 ? (
                  <div key={list.id}>
                    <h3>{index + 1}.</h3> {/* This will show the count starting from 1 */}
                    <h3
                      className="name"
                      style={parseInt(list.pin) === 1 ? { color: "#0480E7" } : {}}
                    >
                      {list.cus_name}
                    </h3>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      onClick={parseInt(list.pin) === 1 ? null : () => pin(list.id)}
                    >
                      <path
                        d="M23.9794 9.75645L20.2735 6.04694C17.7401 3.51113 16.4735 2.24322 15.113 2.54335C13.7526 2.8435 13.1357 4.52694 11.9021 7.89383L11.0672 10.1726C10.7383 11.0702 10.5738 11.519 10.278 11.8662C10.1452 12.0219 9.9942 12.1611 9.82813 12.2806C9.45805 12.5471 8.99763 12.674 8.07681 12.9279C6.00133 13.5 4.9636 13.7861 4.57255 14.4651C4.4035 14.7586 4.31552 15.0919 4.31766 15.4308C4.32261 16.2144 5.08372 16.9763 6.60595 18.5L8.37418 20.2704L2.77931 25.8705C2.4069 26.2433 2.4069 26.8478 2.77931 27.2205C3.15172 27.5933 3.75553 27.5933 4.12795 27.2205L9.72301 21.6201L11.5555 23.4544C13.0874 24.9878 13.8534 25.7545 14.6417 25.7556C14.974 25.7561 15.3005 25.6698 15.5891 25.5051C16.274 25.1143 16.5616 24.0689 17.1369 21.9781C17.3899 21.059 17.5162 20.5994 17.7817 20.2294C17.898 20.0675 18.0327 19.9198 18.1835 19.7893C18.5276 19.4913 18.9735 19.3238 19.8652 18.9888L22.1702 18.1226C25.5 16.8716 27.1649 16.2461 27.4584 14.8894C27.7517 13.5325 26.4942 12.2738 23.9794 9.75645Z"
                        fill={parseInt(list.pin) === 1 ? "#0480E7" : "#1C274C"}
                      />
                    </svg>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      onClick={() => {
                        setName(list.cus_name);
                        setPhoneNumber(list.cus_ph);
                        setAddress(list.cus_address);
                        setNote(list.note);
                      }}
                    >
                      <path
                        d="M6.11475 16.5859L11.4575 21.9284L23.3858 10"
                        stroke="#1C274C"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      onClick={() => unreserve(list.id)}
                    >
                      <path
                        d="M17.625 15L22.7812 9.84375C23.5312 9.09375 23.5312 7.96875 22.7812 7.21875C22.5 6.84375 22.0312 6.5625 21.5625 6.5625C21.0938 6.5625 20.625 6.75 20.25 7.125L15 12.375L9.84375 7.21875C9.09375 6.46875 7.875 6.46875 7.21875 7.21875C6.84375 7.5 6.5625 7.96875 6.5625 8.53125C6.5625 9.09375 6.75 9.46875 7.125 9.84375L12.2813 15L7.125 20.1562C6.84375 20.5312 6.5625 21 6.5625 21.5625C6.5625 22.0312 6.75 22.5 7.125 22.875C7.5 23.25 7.96875 23.4375 8.4375 23.4375C8.90625 23.4375 9.375 23.25 9.75 22.875L14.9062 17.7188L20.0625 22.875C20.8125 23.625 22.0312 23.625 22.6875 22.875C23.4375 22.125 23.4375 20.9062 22.6875 20.25L17.625 15Z"
                        fill="#1C274C"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )
              )}
          </div>
        )}
        {soldOut && parseInt(userInfo.role) === 0 && (
          <div className="actions">
            <button
              className="danger"
              onClick={() => setShowPopup({ ...showPopup, resetTicket: true })}
            >
              Reset Ticket
            </button>
          </div>
        )}
      </div>
      <div className="hideMe" style={{ position: "absolute", top: "110%" }}>
        <div>
          <TicketVoucher
            time={ticketInfo.time}
            number={ticket_number}
            name={name}
            phone={phoneNumber}
            address={address}
            image={ticketInfo.img}
            price={ticketInfo.price}
            date={ticketInfo.date}
            type={ticketInfo.type}
            ref={containerRef}
            setImageLoading={setImageLoading}
          />
        </div>
      </div>
      {showPopup.resetTicket && (
        <PopupBox
          formName="delete-Customer row"
          color="danger"
          close={() => setShowPopup({ ...showPopup, resetTicket: false })}
          actions={[
            { type: "cancel", name: "Cancel" },
            {
              type: "danger",
              name: "Delete",
              func: ConfirmResetTicket,
            },
          ]}
        >
          <div className="trash-can">
            <svg viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.31258 25.8345C2.31258 27.5489 3.76571 28.9516 5.54175 28.9516H18.4584C20.2345 28.9516 21.6876 27.5489 21.6876 25.8345V7.13172H2.31258V25.8345ZM23.3022 2.45603H17.6511L16.0365 0.897461H7.96362L6.34904 2.45603H0.697998V5.57315H23.3022V2.45603Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="right">
            <h3>Are you sure?</h3>
            <p>
              Do you really want to reset this Ticket?
              <br /> This process cannot be undone.
            </p>
          </div>
        </PopupBox>
      )}
    </div>
  );
};

export default PopupBox2;
